locations-domains-metrics {}

.metrics {
    &--stats-container {
        margin-top: 15px;
        div {
            max-width: 48%!important;
            border: solid 1px $lightestGray;
            text-align: center;
            background-color: white;
            height: 100px;
            padding: 20px 0;
            vertical-align: middle;
            margin-bottom: 10px;
            &:nth-of-type(1) {
                padding-top: 25px;
            }
            sup {
                font-size: 12px;
            }
        }
    }
    &--flush-stats-container {
        margin-top: 15px;
        div {
            max-width: 50%!important;
            height: 70px!important;
            border: solid 1px $lightestGray;
            text-align: center;
            background-color: white;
            height: 100px;
            padding: 10px 0;
            vertical-align: middle;
            margin-bottom: 10px;
            &:first-child {
                border-right: 0;
            }
        }
    }
    &--alert {
        border: solid 1px $lightestGray;
        background-color: white;
        max-width: 23%!important;
        padding: 10px 20px;
        margin-bottom: 10px;
        margin-right: 2.5%;
        &:nth-of-type(4) {
            margin-right: 0;
        }
        &.duplicates {
            border-left: solid 4px $duplicates;
        }
        &.redirect302 {
            border-left: solid 4px $redirect302;
        }
        &.contacted {
            border-left: solid 4px $contacted;
        }
        &.no_provider {
            border-left: solid 4px $noprovider;
        }
        &.clear {
            border-left: solid 4px $clear;
        }
        &.no_gbp {
            border-left: solid 4px $nogbp;
        }
    }
    &--title-spacing {
        margin-top: 10px;
        margin-bottom: 20px;
        display: block;
    }
    &--update-metrics-button{
        margin: 10px 0px;
        padding: 0px 15px;
    }
}
