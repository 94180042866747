graph-range-toggle {
    display: block;
    position: absolute;
    top: 39px;
    right: 30px;
    a {
        cursor: pointer;
        font-size: 12px;
        color: $mediumGray;
        line-height: 1.2;
        text-align: left;
        margin: 0 5px;
        &.active,
        &:hover {
            color: $primary;
            text-decoration: underline;
        }
    }
}
