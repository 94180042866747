/*----------  Tags Search  ----------*/

tags-search {
  min-height: 56px;
  border-bottom: solid 1px $borderGray;
  md-chips-wrap {
    width: 100%;
    box-shadow: none!important;
    position: relative;
  }
  &-input {
    .md-chips {
      float: left;
    }
  }
  add-tag-icon md-icon.domain--tags-add {
    font-size: 21px!important;
  }
}

tags-search-input {
  display: block;
}

.tags-search-input {
  &--add-tag-button {
    right: 70px;
  }
  &--edit-tag-button {
    right: 30px;
  }
  &--add-tag-button,
  &--edit-tag-button {
    color: rgb(158, 158, 158);
    position: absolute;
    cursor: pointer;
    top: 17px;
    font-size: 25px!important;
    md-icon {
      font-size: 25px!important;
    }
  }
}
