@each $provider,
$color in $providers {
  tr[md-row] {
    &.#{$provider} td:first-child {
      border-left: solid 4px $color;
    }
    &.#{$provider}-checkbox md-checkbox.md-checked .md-icon {
      background-color: $color;
    }
  }
}

tr.unknown {
  td:first-child {
    border-left: solid 4px white;
  }
}
