.locations--metrics {
    border-bottom: solid 1px $lightestGray;
    .md-headline {
        font-size: 26px;
        font-weight: 500;
    }
    &-metric {
        border: solid 1px $lightestGray;
        text-align: center;
        padding: 25px 0;
        margin-top: 15px;
        margin-bottom: 15px;
        max-width: 250px!important;
        .md-subheader {
            font-weight: 100;
            font-size: 14px;
            margin-top: 10px;
        }
    }
}
