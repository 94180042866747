/*----------  Overlay modal (tags & bulk searching domains)  ----------*/

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.96);
  z-index: 1010;
  &--content-container {
    position: relative;
    width: 50%;
    width: 675px;
    min-height: 745px;
    margin: calc(50% - 36%) auto 0;
    .close-icon {
      cursor: pointer;
      position: absolute;
      right: -20px;
      top: -139px;
      font-size: 41px;
      color: rgba(0, 0, 0, .35);
    }
  }
  &--heading {
    font-size: 20px;
    color: rgba(0, 0, 0, .35);
    margin-bottom: 40px;
  }
}