.navbar {
    background: $darkGray;
    box-shadow: 0px 1px 3.92px 0.06px rgba(0, 0, 0, 0.16);
    z-index: 3;
    &--toolbar {
        height: $toolbarHeight;
        background-color: $primary!important;
        box-shadow: 1px 1.732px 2.82px 0.18px rgba(0, 0, 0, 0.16);
        h1 {
            font-size: 30px;
            letter-spacing: 6.5px;
            margin: 14px auto;
            font-weight: 800;
        }
    }
    lost-main-nav {
        min-width: 255px;
        height: 100%;
    }
    md-list {
        padding: 20px 0;
    }
    md-list-item {
        a {
            height: 55px!important;
            padding: 0 15px;
            margin: 0;
            color: $mediumGray;
            p {
                padding-top: 5px;
            }
            .material-icons {
                margin-left: 10px!important;
                margin-right: 30px!important;
                color: $mediumGray;
            }
            &:hover,
            &.active {
                color: white;
                .material-icons {
                    color: white;
                }
            }
        }
        &.active-route {
            border-left: solid 4px $primary;
            md-icon {
                margin-left: 16px;
                margin-right: 25px;
            }
        }
    }
}
