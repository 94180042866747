/*----------  tags-list  ----------*/

.tags-list {
  &--wrap {
    overflow: scroll;
  }
  &--section {
    border-bottom: rgb(217, 217, 217) solid 1px;
    padding: 0 9px 0 34px;
    p {
      color: rgb(66, 66, 66);
      font-size: 25px;
      margin: 15px 0;
    }
    md-chips-wrap {
      box-shadow: none!important;
      margin: 0 0 40px 30px;
    }
    .md-chip {
      background-color: white;
      font-size: 13px;
      cursor: pointer;
    }
  }
  md-chips .md-input {
    display: none;
  }
  &--alpha-list {
    max-width: 35px!important;
    padding-top: 68px;
    overflow-y: auto;
    div {
      list-style-type: none;
      padding: 0 0 10px;
      a {
        display: block;
        width: 100%;
        cursor: pointer;
        font-weight: 500;
        font-size: 18px;
        color: rgb(152, 152, 152);
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        &.active {
          color: $primary;
        }
      }
    }
  }
}
