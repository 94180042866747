/*----------  Domains List  ----------*/

.domains-table {
  th {
    &:first-child {
      border-left: solid 4px whitesmoke;
    }
  }
}

.domains-list--location-provider-container {
  padding: 0 16px 0 0!important;
}

.domains-list--index-container {
  padding: 0 9px 0 0!important;
}

.domains-list--phone-country-container {
  min-width: 150px;
}

// Override
table.md-table.domains-table:not(.md-row-select) td.md-cell:nth-child(n+2):nth-last-child(n+2),
table.md-table.domains-table:not(.md-row-select) th.md-column:nth-child(n+2):nth-last-child(n+2) {
  padding: 0 6px 0 0!important;
}
