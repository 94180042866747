/*----------  list-map-toggle  ----------*/

locations-domains-content {
  position: relative;
}

list-map-toggle {
  max-width: 130px;
  height: 56px;
  border-left: solid 1px $lightGray;
  right: 0;
  top: 0;
  md-icon {
    text-align: center;
    color: rgb(189, 189, 189);
    margin-top: 17px;
    position: relative;
    cursor: pointer;
    &:focus {
      outline: 0;
    }
    &.active {
      color: $primary;
    }
    &:first-child {
      right: -4px;
    }
    &:last-child {
      left: -10px;
    }
  }
}
