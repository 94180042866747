/*----------  Locations Domains: Right panel;  ----------*/

locations-domains-right-panel, locations-right-panel {
  background-color: $rightPanelGray!important;
  md-content {
    background-color: transparent;
  }
  md-tabs {
    height: 100%;
    md-tabs-content-wrapper {
      // overflow: auto;
      height: 100%;
    }
  }
}
