.tags-add {
  &--container {
    label.recents {
      margin-top: 60px;
      margin-bottom: 13px;
      display: block;
      clear: both;
      font-size: 14px;
      color: $mediumDarkGray;
    }
    md-chips {
      float: left;
      width: 86%;
      margin-right: 5px;
    }
    .md-chips .md-chip-input-container {
      width: 300px;
      input.md-input {
        width: 300px;
      }
    }
    input::webkit-input-placeholder {
      font-size: 24px;
      color: black;
    }
  }
  &--search-form {
    overflow: hidden;
    md-chips-wrap {
      border: solid 1px rgba(0, 0, 0, .15);
      border-radius: 3px;
      background-color: rgb(255, 255, 255);
      width: 100%;
      min-height: 62px;
      padding: 5px 20px;
      box-shadow: none!important;
    }
  }
  &--list {
    ul {
      max-height: 388px;
      overflow-y: auto;
      margin: 0;
      padding: 0;
    }
    li {
      list-style-type: none;
      font-size: 18px;
      color: rgb(97, 97, 97);
      padding: 17px 30px;
      border-top: solid 1px rgba(0, 0, 0, .15);
      &:hover {
        background: rgba(0, 145, 234, .3);
        cursor: pointer;
      }
      &:focus {
        outline: none;
      }
      &.disabled {
        color: rgba(97, 97, 97, 0.56);
        &:hover {
          background: none;
          cursor: default;
        }
      }
    }
  }
  .md-button {
    min-width: 73px;
  }
}
