.filters {
  &--label {
    display: block;
    font-size: 13px;
    color: $mediumGray;
    margin-bottom: -3px;
  }
  &--traffic {
    padding-left: 15px;
    border-left: solid 1px $borderGray;
    max-width: 230px;
  }
  &--prospects {
    padding-left: 15px;
    border-left: solid 1px $borderGray;
    max-width: 260px;
  }
  &--form {
    &-sub-container {
      border-bottom: solid 1px $borderGray;
      padding-bottom: 8px;
      md-radio-button {
        .md-off {
          border-color: $mediumDarkGray!important;
        }
        .md-label {
          font-size: 14px;
          color: $mediumDarkGray
        }
      }
    }
  }
  &--radio-group {
    &-distance {
      .md-label {
        top: 5px;
      }
    }
  }
}
