locations-search {
  position: relative;
  min-height: 58px;
  md-autocomplete-wrap {
    box-shadow: none!important;
  }
  md-autocomplete {
    position: relative;
    z-index: 1;
  }
  md-autocomplete,
  md-autocomplete-wrap,
  input[type="search"] {
    height: 57px;
    background: white;
  }
  input[type="search"] {
    font-size: 16px;
    padding: 5px 0 0 0;
  }
}
