/*----------  Domains Search Input  ----------*/

domains-search-input {
  // flex input
  md-chips-wrap.md-chips {
    box-sizing: border-box;
    display: flex;
    .md-chip-input-container {
      flex: 1;
      box-sizing: border-box;
      input {
        width: 100%;
      }
    }
  }
}

md-icon.domains-search--icon {
  color: rgb(189, 189, 189);
  cursor: pointer;
  padding-top: 17px;
  margin-left: 26px;
  &:focus {
    outline: 0;
  }
  &.active {
    color: $primary;
  }
}

.extra-icon-container {
  max-width: 130px;
}

.search-chips--container {
  &.first {
    .md-chips .md-chip {
      padding: 0 17px 0 12px;
      .md-chip-content {
        max-width: 100%;
        overflow: visible;
        text-overflow: inherit;
      }
    }
  }
}

.search-icon-container {
  min-width: 260px;
}
