/*----------  Domains Content  ----------*/

domains-content {
  position: relative;
}

.domains-list-wrapper {
  overflow: auto;
}

.domains-content__options-menu {
  text-transform: capitalize;
  color: #9e9e9e;
}

.domains-footer {
  min-height: 57px;
  border-top: solid 1px #e0e0e0;
}
