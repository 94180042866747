/*----------  Domains Details - core  ----------*/

@import './info/core';
@import './activity/core';
@import './notes/core';
@import './search/core';
@import './domainDetails/core';
@import './domainsContent/core';
@import './domainsList/core';
@import './domainsColumnToggle/core';
