/*----------  domainsDetails  ----------*/

domain-details {
  md-tabs {
    height: 100%;
    md-tabs-content-wrapper {
      overflow: auto;
      height: 100%;
    }
  }
}
