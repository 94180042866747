/*----------  Typography  ----------*/

.capitalize {
    text-transform: capitalize;
}

.medium {
    font-weight: 500!important;
}

.md-subheader {
    background: none;
}

.subsection-headline {
    font-size: 20px;
    color: $dark;
    font-weight: 500;
    display: block;
    margin-bottom: 25px;
}

.trim {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
