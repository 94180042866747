domain-activity {
  display: block;
}

.activity {
  padding-left: 25px!important;
  .md-headline {
    margin-top: 0;
    font-size: 20px;
    font-weight: 500;
    color: $darkGray;
  }
  &--card {
    border: solid 1px $borderGray;
    border-radius: 6px;
    background-color: white;
    padding: 10px 23px;
    max-width: 92%;
    box-sizing: border-box;
    md-icon {
      font-size: 20px;
      color: rgb(189, 189, 189);
      width: 20px;
      position: relative;
      top: 2px;
      font-weight: 500;
      margin-right: 15px;
    }
    &-container {
      padding-bottom: 25px;
      border-left: solid 1px rgb(230, 230, 230);
      padding-left: 26px;
      position: relative;
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: calc(50% - 20px);
        left: -7px;
        border-radius: 50%;
        width: 14px;
        height: 15px;
        background-color: $mediumDarkGray;
      }
      &.note {
        &:after {
          background-color: $noteAct;
        }
      }
      &.alert {
        &:after {
          background-color: $primary;
        }
      }
      &.provider {
        &:after {
          background-color: $providerAct;
        }
      }
      &.domain_purchased {
        &:after {
          background-color: $domainAct;
        }
      }
    }
    &-title {
      font-size: 15px;
      font-weight: 500;
      color: rgb(97, 97, 97);
    }
    &-content {
      padding: 1px 30px 20px 20px;
      border-radius: 6px;
      background-color: $lightGray;
      margin: 21px 0 27px;
      p {
        font-size: 15px;
        color: rgb(97, 97, 97);
        line-height: 140%;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    &-date {
      font-size: 15px;
      color: $mediumDarkGray;
      font-weight: 500;
    }
  }
}
