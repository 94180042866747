/*----------  Locations Core  ----------*/

@import 'locationsContent/core';
@import 'locationsList/core';
@import 'locationsSearch/core';
@import 'locationsMetrics/core';
@import 'locationsDomainsRightPanel/core';
@import 'locationsDomainsDemographics/core';
@import 'locationsDomainsMetrics/core';
@import 'locationsProviders/core';
