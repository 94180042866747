/*----------  Locations > Domains: Demographics  ----------*/

locations-domains-demographics {
  hr {
    border: 0;
    margin: 0;
    border-bottom: solid 1px $hrGray;
  }
  .md-padding {
    &:first-child {
      padding-bottom: 0;
    }
  }
}

.demographics {
  &--map {
    width: 570px;
    height: 346px;
  }
  &--location-headline {
    margin-top: 25px;
  }
  &--stats-container {
    margin-top: 15px;
    div {
      max-width: 48%!important;
      border: solid 1px $lightestGray;
      text-align: center;
      background-color: white;
      height: 100px;
      padding: 20px 0;
      vertical-align: middle;
      margin-bottom: 10px;
      &:nth-of-type(1) {
        padding-top: 25px;
      }
      sup {
        font-size: 12px;
      }
    }
  }
}
