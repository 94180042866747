/*----------  Domains Search Filter Form  ----------*/

md-icon.domains-search--filters-form-toggle {
  position: absolute;
  right: 20px;
  top: 17px;
  z-index: 1;
  color: rgb(189, 189, 189);
  cursor: pointer;
  &:focus {
    outline: 0;
  }
  &.active {
    color: $primary;
  }
}

domains-search-filters-form {
  display: block;
  padding: 6px 17px;
  clear: both;
  .radio-label {
    display: block;
    margin-bottom: 10px;
  }
  md-radio-button {
    margin-bottom: 10px!important;
    min-width: 60px;
    .md-label {
      min-width: 35px;
    }
  }
  md-chips-wrap {
    box-shadow: none!important;
  }
  .md-chip {
    padding: 0 9px!important;
    height: 28px!important;
    margin-top: 11px!important;
  }
  md-chip-template {
    position: relative;
    top: -3px;
  }
  .distance-container {
    padding-bottom: 20px;
    border-bottom: 0;
  }
  .filters--form-sub-container:last-child {
    border: 0;
  }
}
