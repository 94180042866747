//urls
$url: url('//res.cloudinary.com/ufn/image/upload/v1457124546/lost_assets/flagsprite.png');

%extend_1 {
    display: inline-block;
    background: $url no-repeat;
    overflow: hidden;
    text-indent: -9999px;
    text-align: left;
    width: 70px;
    height: 47px;
}

.flag-afghanistan {
    @extend %extend_1;
    background-position: 0 0;
}

.flag-albania {
    @extend %extend_1;
    background-position: -70px 0;
}

.flag-algeria {
    @extend %extend_1;
    background-position: -140px 0;
}

.flag-andorra {
    @extend %extend_1;
    background-position: -210px 0;
}

.flag-antigua-and-barbuda {
    @extend %extend_1;
    background-position: -280px 0;
}

.flag-argentina {
    @extend %extend_1;
    background-position: -350px 0;
}

.flag-armenia {
    @extend %extend_1;
    background-position: -420px 0;
}

.flag-australia {
    @extend %extend_1;
    background-position: -490px 0;
}

.flag-austria {
    @extend %extend_1;
    background-position: -560px 0;
}

.flag-azerbaijan {
    @extend %extend_1;
    background-position: -630px 0;
}

.flag-bahamas {
    @extend %extend_1;
    background-position: -700px 0;
}

.flag-bahrain {
    @extend %extend_1;
    background-position: -770px 0;
}

.flag-bangladesh {
    @extend %extend_1;
    background-position: -840px 0;
}

.flag-barbados {
    @extend %extend_1;
    background-position: 0 -47px;
}

.flag-belarus {
    @extend %extend_1;
    background-position: -70px -47px;
}

.flag-belgium {
    @extend %extend_1;
    background-position: -140px -47px;
}

.flag-belize {
    @extend %extend_1;
    background-position: -210px -47px;
}

.flag-benin {
    @extend %extend_1;
    background-position: -280px -47px;
}

.flag-bhutan {
    @extend %extend_1;
    background-position: -350px -47px;
}

.flag-bolivia {
    @extend %extend_1;
    background-position: -420px -47px;
}

.flag-bosnia-and-herzegovina {
    @extend %extend_1;
    background-position: -490px -47px;
}

.flag-botswana {
    @extend %extend_1;
    background-position: -560px -47px;
}

.flag-brazil {
    @extend %extend_1;
    background-position: -630px -47px;
}

.flag-brunei {
    @extend %extend_1;
    background-position: -700px -47px;
}

.flag-bulgaria {
    @extend %extend_1;
    background-position: -770px -47px;
}

.flag-burkina-faso {
    @extend %extend_1;
    background-position: -840px -47px;
}

.flag-burundi {
    @extend %extend_1;
    background-position: 0 -94px;
}

.flag-cambodia {
    @extend %extend_1;
    background-position: -70px -94px;
}

.flag-cameroon {
    @extend %extend_1;
    background-position: -140px -94px;
}

.flag-canada {
    @extend %extend_1;
    background-position: -210px -94px;
}

.flag-cape-verde {
    @extend %extend_1;
    background-position: -280px -94px;
}

.flag-central-african-republic {
    @extend %extend_1;
    background-position: -350px -94px;
}

.flag-chad {
    @extend %extend_1;
    background-position: -420px -94px;
}

.flag-chile {
    @extend %extend_1;
    background-position: -490px -94px;
}

.flag-china {
    @extend %extend_1;
    background-position: -560px -94px;
}

.flag-colombia {
    @extend %extend_1;
    background-position: -630px -94px;
}

.flag-comoros {
    @extend %extend_1;
    background-position: -700px -94px;
}

.flag-congo-democratic {
    @extend %extend_1;
    background-position: -770px -94px;
}

.flag-congo-republic {
    @extend %extend_1;
    background-position: -840px -94px;
}

.flag-costa-rica {
    @extend %extend_1;
    background-position: 0 -141px;
}

.flag-cote-divoire {
    @extend %extend_1;
    background-position: -70px -141px;
}

.flag-croatia {
    @extend %extend_1;
    background-position: -140px -141px;
}

.flag-cuba {
    @extend %extend_1;
    background-position: -210px -141px;
}

.flag-cyprus {
    @extend %extend_1;
    background-position: -280px -141px;
}

.flag-czech-republic {
    @extend %extend_1;
    background-position: -350px -141px;
}

.flag-denmark {
    @extend %extend_1;
    background-position: -420px -141px;
}

.flag-djibouti {
    @extend %extend_1;
    background-position: -490px -141px;
}

.flag-dominica {
    @extend %extend_1;
    background-position: -560px -141px;
}

.flag-dominican-republic {
    @extend %extend_1;
    background-position: -630px -141px;
}

.flag-east-timor {
    @extend %extend_1;
    background-position: -700px -141px;
}

.flag-ecuador {
    @extend %extend_1;
    background-position: -770px -141px;
}

.flag-egypt {
    @extend %extend_1;
    background-position: -840px -141px;
}

.flag-el-salvador {
    @extend %extend_1;
    background-position: 0 -188px;
}

.flag-equatorial-guinea {
    @extend %extend_1;
    background-position: -70px -188px;
}

.flag-eritrea {
    @extend %extend_1;
    background-position: -140px -188px;
}

.flag-estonia {
    @extend %extend_1;
    background-position: -210px -188px;
}

.flag-ethiopia {
    @extend %extend_1;
    background-position: -280px -188px;
}

.flag-fiji {
    @extend %extend_1;
    background-position: -350px -188px;
}

.flag-finland {
    @extend %extend_1;
    background-position: -420px -188px;
}

.flag-france {
    @extend %extend_1;
    background-position: -490px -188px;
}

.flag-gabon {
    @extend %extend_1;
    background-position: -560px -188px;
}

.flag-gambia {
    @extend %extend_1;
    background-position: -630px -188px;
}

.flag-georgia {
    @extend %extend_1;
    background-position: -700px -188px;
}

.flag-germany {
    @extend %extend_1;
    background-position: -770px -188px;
}

.flag-ghana {
    @extend %extend_1;
    background-position: -840px -188px;
}

.flag-grecee {
    @extend %extend_1;
    background-position: 0 -235px;
}

.flag-grenada {
    @extend %extend_1;
    background-position: -70px -235px;
}

.flag-guatemala {
    @extend %extend_1;
    background-position: -140px -235px;
}

.flag-guinea-bissau {
    @extend %extend_1;
    background-position: -210px -235px;
}

.flag-guinea {
    @extend %extend_1;
    background-position: -280px -235px;
}

.flag-guyana {
    @extend %extend_1;
    background-position: -350px -235px;
}

.flag-haiti {
    @extend %extend_1;
    background-position: -420px -235px;
}

.flag-honduras {
    @extend %extend_1;
    background-position: -490px -235px;
}

.flag-hungary {
    @extend %extend_1;
    background-position: -560px -235px;
}

.flag-iceland {
    @extend %extend_1;
    background-position: -630px -235px;
}

.flag-india {
    @extend %extend_1;
    background-position: -700px -235px;
}

.flag-indonesia {
    @extend %extend_1;
    background-position: -770px -235px;
}

.flag-iran {
    @extend %extend_1;
    background-position: -840px -235px;
}

.flag-iraq {
    @extend %extend_1;
    background-position: 0 -282px;
}

.flag-ireland {
    @extend %extend_1;
    background-position: -70px -282px;
}

.flag-israel {
    @extend %extend_1;
    background-position: -140px -282px;
}

.flag-italy {
    @extend %extend_1;
    background-position: -210px -282px;
}

.flag-jamaica {
    @extend %extend_1;
    background-position: -280px -282px;
}

.flag-japan {
    @extend %extend_1;
    background-position: -350px -282px;
}

.flag-jordan {
    @extend %extend_1;
    background-position: -420px -282px;
}

.flag-kazakhstan {
    @extend %extend_1;
    background-position: -490px -282px;
}

.flag-kenya {
    @extend %extend_1;
    background-position: -560px -282px;
}

.flag-kiribati {
    @extend %extend_1;
    background-position: -630px -282px;
}

.flag-korea-north {
    @extend %extend_1;
    background-position: -700px -282px;
}

.flag-korea-south {
    @extend %extend_1;
    background-position: -770px -282px;
}

.flag-kosovo {
    @extend %extend_1;
    background-position: -840px -282px;
}

.flag-kuwait {
    @extend %extend_1;
    background-position: 0 -329px;
}

.flag-kyrgyzstan {
    @extend %extend_1;
    background-position: -70px -329px;
}

.flag-laos {
    @extend %extend_1;
    background-position: -140px -329px;
}

.flag-latvia {
    @extend %extend_1;
    background-position: -210px -329px;
}

.flag-lebanon {
    @extend %extend_1;
    background-position: -280px -329px;
}

.flag-lesotho {
    @extend %extend_1;
    background-position: -350px -329px;
}

.flag-liberia {
    @extend %extend_1;
    background-position: -420px -329px;
}

.flag-libya {
    @extend %extend_1;
    background-position: -490px -329px;
}

.flag-liechtenstein {
    @extend %extend_1;
    background-position: -560px -329px;
}

.flag-lithuania {
    @extend %extend_1;
    background-position: -630px -329px;
}

.flag-luxembourg {
    @extend %extend_1;
    background-position: -700px -329px;
}

.flag-macedonia {
    @extend %extend_1;
    background-position: -770px -329px;
}

.flag-madagascar {
    @extend %extend_1;
    background-position: -840px -329px;
}

.flag-malawi {
    @extend %extend_1;
    background-position: 0 -376px;
}

.flag-malaysia {
    @extend %extend_1;
    background-position: -70px -376px;
}

.flag-maldives {
    @extend %extend_1;
    background-position: -140px -376px;
}

.flag-mali {
    @extend %extend_1;
    background-position: -210px -376px;
}

.flag-malta {
    @extend %extend_1;
    background-position: -280px -376px;
}

.flag-marshall-islands {
    @extend %extend_1;
    background-position: -350px -376px;
}

.flag-mauritania {
    @extend %extend_1;
    background-position: -420px -376px;
}

.flag-mauritius {
    @extend %extend_1;
    background-position: -490px -376px;
}

.flag-mexico {
    @extend %extend_1;
    background-position: -560px -376px;
}

.flag-micronesia-federated {
    @extend %extend_1;
    background-position: -630px -376px;
}

.flag-moldova {
    @extend %extend_1;
    background-position: -700px -376px;
}

.flag-monaco {
    @extend %extend_1;
    background-position: -770px -376px;
}

.flag-mongolia {
    @extend %extend_1;
    background-position: -840px -376px;
}

.flag-montenegro {
    @extend %extend_1;
    background-position: 0 -423px;
}

.flag-morocco {
    @extend %extend_1;
    background-position: -70px -423px;
}

.flag-mozambique {
    @extend %extend_1;
    background-position: -140px -423px;
}

.flag-myanmar {
    @extend %extend_1;
    background-position: -210px -423px;
}

.flag-namibia {
    @extend %extend_1;
    background-position: -280px -423px;
}

.flag-nauru {
    @extend %extend_1;
    background-position: -350px -423px;
}

.flag-nepal {
    @extend %extend_1;
    background-position: -420px -423px;
}

.flag-netherlands {
    @extend %extend_1;
    background-position: -490px -423px;
}

.flag-new-zealand {
    @extend %extend_1;
    background-position: -560px -423px;
}

.flag-nicaragua {
    @extend %extend_1;
    background-position: -630px -423px;
}

.flag-niger {
    @extend %extend_1;
    background-position: -700px -423px;
}

.flag-nigeria {
    @extend %extend_1;
    background-position: -770px -423px;
}

.flag-norway {
    @extend %extend_1;
    background-position: -840px -423px;
}

.flag-oman {
    @extend %extend_1;
    background-position: 0 -470px;
}

.flag-pakistan {
    @extend %extend_1;
    background-position: -70px -470px;
}

.flag-palau {
    @extend %extend_1;
    background-position: -140px -470px;
}

.flag-panama {
    @extend %extend_1;
    background-position: -210px -470px;
}

.flag-papua-new-guinea {
    @extend %extend_1;
    background-position: -280px -470px;
}

.flag-paraguay {
    @extend %extend_1;
    background-position: -350px -470px;
}

.flag-peru {
    @extend %extend_1;
    background-position: -420px -470px;
}

.flag-philippines {
    @extend %extend_1;
    background-position: -490px -470px;
}

.flag-poland {
    @extend %extend_1;
    background-position: -560px -470px;
}

.flag-portugal {
    @extend %extend_1;
    background-position: -630px -470px;
}

.flag-qatar {
    @extend %extend_1;
    background-position: -700px -470px;
}

.flag-romania {
    @extend %extend_1;
    background-position: -770px -470px;
}

.flag-russia {
    @extend %extend_1;
    background-position: -840px -470px;
}

.flag-rwanda {
    @extend %extend_1;
    background-position: 0 -517px;
}

.flag-saint-kitts-and-nevis {
    @extend %extend_1;
    background-position: -70px -517px;
}

.flag-saint-lucia {
    @extend %extend_1;
    background-position: -140px -517px;
}

.flag-saint-vincent-and-the-grenadines {
    @extend %extend_1;
    background-position: -210px -517px;
}

.flag-samoa {
    @extend %extend_1;
    background-position: -280px -517px;
}

.flag-san-marino {
    @extend %extend_1;
    background-position: -350px -517px;
}

.flag-sao-tome-and-principe {
    @extend %extend_1;
    background-position: -420px -517px;
}

.flag-saudi-arabia {
    @extend %extend_1;
    background-position: -490px -517px;
}

.flag-senegal {
    @extend %extend_1;
    background-position: -560px -517px;
}

.flag-serbia {
    @extend %extend_1;
    background-position: -630px -517px;
}

.flag-seychelles {
    @extend %extend_1;
    background-position: -700px -517px;
}

.flag-sierra-leone {
    @extend %extend_1;
    background-position: -770px -517px;
}

.flag-singapore {
    @extend %extend_1;
    background-position: -840px -517px;
}

.flag-slovakia {
    @extend %extend_1;
    background-position: 0 -564px;
}

.flag-slovenia {
    @extend %extend_1;
    background-position: -70px -564px;
}

.flag-solomon-islands {
    @extend %extend_1;
    background-position: -140px -564px;
}

.flag-somalia {
    @extend %extend_1;
    background-position: -210px -564px;
}

.flag-south-africa {
    @extend %extend_1;
    background-position: -280px -564px;
}

.flag-south-sudan {
    @extend %extend_1;
    background-position: -350px -564px;
}

.flag-spain {
    @extend %extend_1;
    background-position: -420px -564px;
}

.flag-sri-lanka {
    @extend %extend_1;
    background-position: -490px -564px;
}

.flag-sudan {
    @extend %extend_1;
    background-position: -560px -564px;
}

.flag-suriname {
    @extend %extend_1;
    background-position: -630px -564px;
}

.flag-swaziland {
    @extend %extend_1;
    background-position: -700px -564px;
}

.flag-sweden {
    @extend %extend_1;
    background-position: -770px -564px;
}

.flag-switzerland {
    @extend %extend_1;
    background-position: -840px -564px;
}

.flag-syria {
    @extend %extend_1;
    background-position: 0 -611px;
}

.flag-taiwan {
    @extend %extend_1;
    background-position: -70px -611px;
}

.flag-tajikistan {
    @extend %extend_1;
    background-position: -140px -611px;
}

.flag-tanzania {
    @extend %extend_1;
    background-position: -210px -611px;
}

.flag-thailand {
    @extend %extend_1;
    background-position: -280px -611px;
}

.flag-togo {
    @extend %extend_1;
    background-position: -350px -611px;
}

.flag-tonga {
    @extend %extend_1;
    background-position: -420px -611px;
}

.flag-trinidad-and-tobago {
    @extend %extend_1;
    background-position: -490px -611px;
}

.flag-tunisia {
    @extend %extend_1;
    background-position: -560px -611px;
}

.flag-turkey {
    @extend %extend_1;
    background-position: -630px -611px;
}

.flag-turkmenistan {
    @extend %extend_1;
    background-position: -700px -611px;
}

.flag-tuvalu {
    @extend %extend_1;
    background-position: -770px -611px;
}

.flag-uganda {
    @extend %extend_1;
    background-position: -840px -611px;
}

.flag-ukraine {
    @extend %extend_1;
    background-position: 0 -658px;
}

.flag-united-arab-emirates {
    @extend %extend_1;
    background-position: -70px -658px;
}

.flag-united-kingdom {
    @extend %extend_1;
    background-position: -140px -658px;
}

.flag-united-states-of-america {
    @extend %extend_1;
    background-position: -210px -658px;
}

.flag-uruguay {
    @extend %extend_1;
    background-position: -280px -658px;
}

.flag-uzbekistan {
    @extend %extend_1;
    background-position: -350px -658px;
}

.flag-vanuatu {
    @extend %extend_1;
    background-position: -420px -658px;
}

.flag-vatican-city {
    @extend %extend_1;
    background-position: -490px -658px;
}

.flag-venezuela {
    @extend %extend_1;
    background-position: -560px -658px;
}

.flag-vietnam {
    @extend %extend_1;
    background-position: -630px -658px;
}

.flag-yemen {
    @extend %extend_1;
    background-position: -700px -658px;
}

.flag-zambia {
    @extend %extend_1;
    background-position: -770px -658px;
}

.flag-zimbabwe {
    @extend %extend_1;
    background-position: -840px -658px;
}
