/*----------  Locations List Core  ----------*/

locations-list {
    overflow: auto;
    &::-webkit-scrollbar {
        display: none;
    }
}

.flag-icon {
    width: 29px;
    height: 19px;
    display: block;
    background: $sprite top left no-repeat;
    &.USA {
        background-position: 0 -53px;
    }
}

.locations {
    &--table {
        &-index {
            width: 20px;
        }
        &-country {
            width: 30px;
            padding-right: 40px!important;
        }
        &-population {
            width: 240px;
        }
        &-score {
            width: 30px;
        }
        &-adspend {
            width: 30px;
        }
    }
}
