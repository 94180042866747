$providersSprite: url('//res.cloudinary.com/ufn/image/upload/v1457124747/lost_assets/Provider-Logos_1.png') no-repeat;
$lovinglyicon: url('https://res.cloudinary.com/ufn/image/upload/c_scale,h_20,w_20/v1498485660/lovingly-logos/lovingly-notification-icon.png');
$gravityFreeIcon: url('http://res.cloudinary.com/ufn/image/upload/c_scale,w_38/v1488489676/gravity_free.png');


/*----------  Providers  ----------*/

// Map icons
@each $provider,
$color in $providers {
  .map-icon--#{$provider} {
    border: 0;
    background: transparent;
    svg {
      fill-rule: evenodd;
      fill: $color;
    }
  }
}



// Provider logo icons
.provider-icon {
  display: block;
  width: 49px;
  height: 47px;
  background: $providersSprite;
  background-size: 110px 446px;
  background-position: 50px 50px;
  &.websystems {
    background-position: 3px -102px
  }
  &.media99 {
    background-position: -4px -387px;
    height: 31px;
  }
  &.bloomnet {
    background-position: -56px -106px;
  }
  &.floranext {
    background-position: 0px 6px;
  }
  &.fsn {
    background-position: -57px -68px;
  }
  &.bloomnation {
    background-position: -59px -146px;
  }
  &.teleflora {
    background-position: 6px -166px;
    background-size: 80px 326px;
  }
  &.ftd {
    background-position: -56px -32px
  }
  &.rfk {
    background-position: -61px -235px;
  }
  &.lafs {
    background-position: -64px -6px;
  }
  &.squarespace {
    background-position: -4px -279px;
  }
  &.vistaprint {
    background-position: -4px -190px;
  }
  &.bigcommerce {
    background-position: -61px -193px;
  }
  &.volusion {
    background-position: -3px -145px
  }
  &.flowerslocal {
    background-position: -63px -410px;
    height: 30px;
  }
  &.yahoostore {
    background-position: 2px -74px;
    height: 40px;
  }
  &.pfs {
    background-position: -4px -358px;
    height: 27px;
  }
  &.epicflowers {
    background-position: -63px -317px;
  }
  &.shopify {
    background-position: -5px -315px;
  }
  &.floristboard {
    background-position: -69px -378px;
    height: 40px;
  }
  &.webshop101 {
    background-position: -68px -349px;
  }
  &.ypf {
    background-position: -3px -32px;
  }
  &.gravityfree {
    background: $gravityFreeIcon 0 5px no-repeat;
  }
}

.provider-icon.ufn {
  background: $lovinglyicon 10px 13px no-repeat;
}
