/*----------  Variables  ----------*/

$toolbarHeight: 60px;
$dark: rgb(38, 50, 56);
$darkGray: rgb(66, 66, 66);
$darkerGray: rgb(61, 61, 61);
$mediumGray: rgb(158, 158, 158);
$mediumDarkGray: rgb(189, 189, 189);
$lightGray: rgb(245, 245, 245);
$lighterGray: rgb(200, 200, 200);
$lightestGray: rgb(217, 217, 217);
$rightPanelGray: rgb(250, 250, 250);
$primary: rgb(0, 145, 234);
$primaryContrast: rgb(79, 195, 247);
$borderGray: rgb(224, 224, 224);
$hrGray: $lightGray;
$chipGray: $mediumDarkGray;

/*----------  Alerts  ----------*/

$duplicates: rgb(171, 71, 188);
$redirect302: rgb(255, 143, 0);
$contacted: rgb(255, 234, 0);
$noprovider: rgb(240, 98, 146);
$clear: rgb(0, 200, 83);
$contacted: rgb(255, 202, 40);
$nogbp: $primary;

/*----------  Activity  ----------*/

$noteAct: rgb(136, 192, 87);
$providerAct: rgb(238, 175, 75);
$domainAct: rgb(151, 119, 168);

/*----------  URLs  ----------*/

$sprite: url('//res.cloudinary.com/ufn/image/upload/v1457021301/lost_assets/sprite.png');
@import 'flags';
