/*----------  Hidden columns  ----------*/

.domains-list {
  &--address,
  &--phone,
  &--email,
  &--owner,
  &--score,
  &--status,
  &--ad_spend,
  &--traffic,
  &--https,
  &--clear,
  &--score {
    display: none;
  }
}

domains-list {
  &.address {
    .domains-list--address {
      display: table-cell;
    }
  }
  &.phone {
    .domains-list--phone {
      display: table-cell;
    }
  }
  &.email {
    .domains-list--email {
      display: table-cell;
    }
  }
  &.status {
    .domains-list--status {
      display: table-cell;
    }
  }
  &.owner {
    .domains-list--owner {
      display: table-cell;
    }
  }
  &.ad_spend {
    .domains-list--ad_spend {
      display: table-cell;
    }
  }
  &.https {
    .domains-list--https {
      display: table-cell;
    }
  }
  &.clear {
    .domains-list--clear {
      display: table-cell;
    }
  }
  &.traffic {
    .domains-list--traffic {
      display: table-cell;
    }
  }
  &.score {
    .domains-list--score {
      display: table-cell;
    }
  }
}


/*----------  Domains Column Toggle  ----------*/

domains-column-toggle {
  padding-right: 20px;
  md-chips-wrap {
    box-shadow: none!important;
  }
}
