/*----------  Map CSS  ----------*/

map-content {
  list-map-toggle {
    display: none
  }
  .search-icon-container {
    min-width: 130px;
  }
}

.loading {
  margin: 20px 30px;
  font-size: 14px;
}

.trackduck-anonymous.td-br{
  right: 60px;
}
