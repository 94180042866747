.search-form {
  position: relative;
  overflow: hidden;
  background: white;
  padding-left: 70px;
  .md-chip-content {
    font-size: 12px!important;
  }
  md-chips {
    display: block;
    width: 100%;
    .md-chip {
      background: $primary!important;
      color: white!important;
      md-icon {
        color: white!important;
        font-size: 12px !important;
      }
    }
    .md-chips.md-focused {
      box-shadow: none;
    }
  }
  md-chips-wrap {
    padding-top: 8px;
    // max-width: calc(100% - 100px);
    // padding-left: 70px;
    box-shadow: none;
  }
  md-icon {
    float: left;
    &.search-icon {
      position: absolute;
      z-index: 60;
      top: 20px;
      left: 30px;
      color: $lighterGray;
    }
  }
}
