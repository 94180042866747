.rightpanel {
  md-tabs {
    md-pagination-wrapper {
      width: 100%!important;
    }
    md-tabs-canvas {
      height: 55px;
    }
    md-ink-bar {
      bottom: -7px;
    }
    md-tab-item {
      height: 55px;
      span {
        font-size: 18px;
        color: $mediumGray;
        text-align: center;
        font-weight: 400;
        position: relative;
        top: 5px;
        text-transform: capitalize;
      }
      &.md-active {
        span {
          color: $primary;
        }
      }
    }
  }
  locations-right-panel {
    md-tab-item {
      width: 50%;
    }
  }
  locations-domains-right-panel,
  domain-details {
    md-tab-item {
      width: 33.33%;
    }
  }
  md-tabs[md-border-bottom]:not(.md-dynamic-height) md-tabs-content-wrapper {
    top: 55px;
  }
}
