/*----------  Breadcrumbs  ----------*/

.breadcrumbs {
  max-height: 60px!important;
  z-index: 1;
  &--shadow {
    box-shadow: 1px 1.732px 2.82px 0.18px rgba(0, 0, 0, 0.16);
    z-index: 2;
    position: relative;
  }
  &--toolbar {
    height: $toolbarHeight;
    background-color: $primary!important;
    &-crumbs {
      font-size: 16px;
      color: rgba(255, 255, 255, 0.541);
      font-weight: 500;
      text-align: left;
      padding-left: 7px;
      margin-left: 25px;
      margin-top: 22px;
    }
  }
}
