/*----------  Toggle button group (search and column toggle)  ----------*/

.toggle-button-group {
  md-chip-template.ng-binding.ng-scope:focus {
    outline: 0;
  }
  .md-chips .md-chip {
    cursor: pointer;
    user-select: none;
    background-color: white;
    border: 1px solid $chipGray;
    color: $chipGray;
    font-size: 13px;
    &.toggled {
      background-color: $chipGray;
      color: white;
    }
  }
}
