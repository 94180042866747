/*----------  Shop info  ----------*/

domain-info {
  display: block;
}

.domainTraffic--chart {
  border: solid 1px rgba(0, 0, 0, 0.24);
}

.domain {
  &--info {
    &-location-map {
      width: 100%;
      height: 200px;
    }
    &-address {
      font-size: 14px;
      line-height: 140%;
      letter-spacing: 0.5px;
      margin-top: 15px;
      p {
        margin: 0;
        font-size: 15px;
      }
      .md-button {
        margin: 0;
      }
    }
    &-select {
      &-container {
        margin: -4px 0 0 0;
        background: $primary;
        padding: 20px 20px 10px 20px;
        box-shadow: 0px 3px 7.84px 0.16px rgba(0, 0, 0, 0.24);
        .md-headline {
          font-size: 24px;
          color: rgb(255, 255, 255);
          font-weight: 500;
        }
        .md-subheader {
          font-size: 16px;
          color: $primaryContrast;
          background: transparent;
        }
        md-checkbox {
          float: left;
          position: relative;
          left: 2px;
          .md-icon {
            border-color: white;
          }
        }
      }
      &-title-container {
        position: relative;
        left: -10px;
        top: -5px;
      }
    }
    &-stats {
      padding-top: 10px;
      &-container {
        padding: 26px 10px 6px 23px;
      }
      &-stat {
        max-width: 130px!important;
        height: 75px;
        border: solid 1px $lightestGray;
        text-align: center;
        background-color: white;
        padding-top: 10px;
        &:first-child {
          margin-right: 25px;
        }
      }
    }
  }
  &--tags {
    &-container {
      padding: 25px;
      border-bottom: solid 1px $hrGray;
      border-top: solid 1px $hrGray;
      md-chip {
        display: inline-block;
        border: solid 1px $mediumGray;
        color: $mediumGray;
        font-size: 12px;
        font-family: "Myriad Pro", "Roboto", sans-serif;
        background: white;
        padding: 2px 10px;
        letter-spacing: 0.5px;
        position: relative;
        top: -2px;
        border-radius: 50px;
        margin-right: 15px;
        margin-bottom: 5px;
        md-icon {
          width: 15px;
          margin-left: 7px;
          cursor: pointer;
        }
      }
      &-title {
        display: block;
        float: left;
        font-size: 12px;
        font-family: "Myriad Pro", "Roboto", sans-serif;
        color: $mediumGray;
        margin-right: 15px;
        padding-top: 5px;
      }
    }
    &-add {
      font-size: 16px!important;
      position: relative;
      top: 3px;
      cursor: pointer;
      color: $mediumGray;
    }
  }
  &--traffic-container {
    position: relative;
    padding: 35px 20px;
  }
}
