.notes {
  .md-headline {
    font-size: 20px;
    font-weight: 500;
  }
  .domain--info-address {
    color: #9e9e9e;
    padding: 0;
    margin-top: 25px;
    margin-bottom: 30px;
  }
  &--shop-contacted-container {
    font-size: 16px;
    color: $darkGray;
    md-checkbox.md-checked {
      .md-icon {
        background: $contacted;
      }
    }
  }
  &--edit-textarea {
    border: 0;
    background: none;
    resize: vertical;
    &:focus {
      outline: none;
    }
  }
  &--button-container {
    max-height: 50px;
    min-height: 50px;
    button {
      color: $primary;
    }
  }
  &--note-form-container {
    height: 240px;
    .md-headline {
      font-size: 20px;
      font-weight: 500;
    }
    textarea {
      appearance: none;
      margin-top: 30px;
      padding: 0 30px 30px;
      width: 100%;
      border: 0;
      height: 140px;
      resize: none;
      &:focus {
        outline: none;
      }
    }
    form {
      position: relative;
    }
    .md-button {
      position: absolute;
      top: 120px;
      right: -10px;
      color: $primary;
    }
  }
  &--past-note {
    &-container {
      .md-headline {
        font-size: 20px;
        font-weight: 500;
      }
    }
    &-note {
      position: relative;
      margin-bottom: 10px;
    }
    &-date {
      display: block;
      font-size: 16px;
      color: $dark;
    }
    &-author {
      display: block;
      font-size: 14px;
      color: $mediumGray;
      margin-bottom: 0;
      margin-top: 5px;
    }
    &-content {
      font-size: 16px;
      color: rgb(189, 189, 189);
      margin: 0 0 20px;
      position: relative;
      top: 5px;
    }
    &-icon {
      color: $mediumDarkGray;
      cursor: pointer;
    }
    &-icon-container {
      // position: absolute;
      // top: 65px;
      // right: 0;
      md-icon:first-child {
        margin-right: 15px;
      }
    }
  }
}
