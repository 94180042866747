md-backdrop.md-edit-dialog-backdrop {
  z-index: 80
}

md-edit-dialog {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: fixed;
  z-index: 81;
  background-color: #f9f9f9;
  border-radius: 2px;
  cursor: default
}

md-edit-dialog>.md-content {
  padding: 16px 24px 0
}

md-edit-dialog>.md-content .md-title {
  color: rgba(0, 0, 0, .87);
  margin-bottom: 8px
}

md-edit-dialog>.md-content md-input-container {
  margin: 0;
  font-size: 13px
}

md-edit-dialog>.md-content md-input-container input {
  float: none
}

md-edit-dialog>.md-content md-input-container .md-errors-spacer {
  min-height: auto;
  min-width: auto;
  color: rgba(0, 0, 0, .54)
}

md-edit-dialog>.md-content md-input-container .md-errors-spacer .md-char-counter {
  padding: 5px 2px 5px 0
}

md-edit-dialog>.md-content md-input-container [ng-message] {
  padding: 5px 0 5px 2px
}

md-edit-dialog>.md-actions {
  margin: 0 16px 8px
}

md-edit-dialog>.md-actions .md-button {
  margin: 0;
  min-width: initial
}

md-edit-dialog>.md-actions .md-button+.md-button {
  margin-left: 8px
}

.md-table-pagination {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  box-sizing: border-box;
  height: 56px;
  padding: 0 24px;
  font-size: 12px;
  color: rgba(0, 0, 0, .54);
  border-top: 1px rgba(0, 0, 0, .12) solid
}

.md-table-pagination md-select {
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  min-width: 64px
}

.md-table-pagination md-select:not([disabled]):focus .md-select-value {
  color: rgba(0, 0, 0, .54)
}

.md-table-pagination md-select .md-select-value {
  -webkit-flex-grow: initial;
  -ms-flex-positive: initial;
  flex-grow: initial
}

.md-table-pagination md-select .md-select-value span.md-select-icon {
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center
}

.md-table-pagination md-select .md-select-value span.md-select-icon:after {
  top: initial;
  -webkit-transform: scaleY(0.5) scaleX(1);
  transform: scaleY(0.5) scaleX(1)
}

.md-table-pagination>md-select+span {
  margin-left: 32px
}

.md-table-pagination>.md-button.md-icon-button {
  margin: 0
}

.md-table-pagination>span+.md-button.md-icon-button {
  margin-left: 20px
}

.md-table-pagination>.md-button.md-icon-button:last-child {
  margin-right: -16px
}

md-select.md-table-select {
  margin: 0
}

md-select.md-table-select>.md-select-value {
  padding: 0;
  min-width: initial;
  min-height: 24px;
  border-bottom: 0!important
}

md-select.md-table-select>.md-select-value>span {
  height: auto;
  -webkit-transform: none!important;
  transform: none!important
}

md-select.md-table-select>.md-select-value>span.md-select-icon {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 24px;
  margin: 0
}

md-select.md-table-select>.md-select-value>span.md-select-icon:after {
  top: initial
}

.md-select-menu-container.md-pagination-select,
.md-select-menu-container.md-table-select {
  margin-left: -2px;
  border-radius: 2px
}

.md-select-menu-container.md-pagination-select md-content,
.md-select-menu-container.md-pagination-select md-select-menu,
.md-select-menu-container.md-table-select md-content,
.md-select-menu-container.md-table-select md-select-menu {
  border-radius: inherit
}

.md-select-menu-container.md-pagination-select md-content,
.md-select-menu-container.md-table-select md-content {
  padding: 0
}

.md-select-menu-container.md-table-select .md-text {
  font-size: 13px
}

.md-select-menu-container.md-pagination-select .md-text {
  font-size: 12px
}

md-toolbar.md-table-toolbar {
  box-shadow: none
}

md-toolbar.md-table-toolbar.md-default-theme:not(.md-menu-toolbar).md-default,
md-toolbar.md-table-toolbar:not(.md-menu-toolbar).md-default {
  background-color: #fff;
  color: rgba(0, 0, 0, .87)
}

md-toolbar.md-table-toolbar.md-default-theme:not(.md-menu-toolbar).md-default .md-button,
md-toolbar.md-table-toolbar:not(.md-menu-toolbar).md-default .md-button {
  color: rgba(0, 0, 0, .87)
}

@media only screen and (max-width:959px) and (min-width:0) and (orientation:landscape) {
  md-toolbar.md-table-toolbar .md-toolbar-tools {
    height: 64px;
    max-height: initial
  }
}

md-toolbar.md-table-toolbar .md-toolbar-tools {
  padding: 0 24px
}

md-toolbar.md-table-toolbar .md-toolbar-tools md-icon {
  color: rgba(0, 0, 0, .54)
}

md-toolbar.md-table-toolbar .md-toolbar-tools>.md-button.md-icon-button {
  margin: 0
}

md-toolbar.md-table-toolbar .md-toolbar-tools>.md-button.md-icon-button:first-child {
  margin-left: -12px
}

md-toolbar.md-table-toolbar .md-toolbar-tools>.md-button.md-icon-button:last-child {
  margin-right: -12px
}

md-card>md-table-container:first-child,
md-card>md-toolbar.md-table-toolbar:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px
}

md-card>md-table-container:last-child,
md-card>md-toolbar.md-table-toolbar:last-child {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px
}

md-table-container {
  display: block;
  max-width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch
}

table.md-table {
  width: 100%;
  border-spacing: 0;
  overflow: hidden
}

table.md-table thead.md-head>tr.md-row {
  height: 56px
}

table.md-table tbody.md-body>tr.md-row,
table.md-table tfoot.md-foot>tr.md-row {
  height: 48px
}

table.md-table thead.md-head+.md-table-progress md-progress-linear {
  top: -3px
}

table.md-table .md-table-progress th {
  padding: 0
}

table.md-table .md-table-progress th md-progress-linear {
  height: 0;
  transition: opacity 1s
}

table.md-table .md-table-progress th md-progress-linear.ng-hide {
  opacity: 0
}

table.md-table .md-table-progress th md-progress-linear>.md-container {
  height: 3px;
  top: 0;
  transition: none
}

table.md-table .md-table-progress th md-progress-linear>.md-container>.md-bar {
  height: 3px
}

table.md-table th.md-column {
  color: rgba(0, 0, 0, .54);
  font-size: 12px;
  font-weight: 700;
  white-space: nowrap
}

table.md-table th.md-column.md-sort {
  cursor: pointer
}

table.md-table th.md-column md-icon {
  height: 16px;
  width: 16px;
  font-size: 16px!important;
  line-height: 16px!important
}

table.md-table th.md-column md-icon.md-sort-icon {
  color: rgba(0, 0, 0, .26);
  opacity: 0;
  transition: -webkit-transform .25s, opacity .25s;
  transition: transform .25s, opacity .25s
}

table.md-table th.md-column md-icon.md-sort-icon.md-asc {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg)
}

table.md-table th.md-column md-icon.md-sort-icon.md-desc {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg)
}

table.md-table th.md-column md-icon:not(:first-child) {
  margin-left: 8px
}

table.md-table th.md-column md-icon:not(:last-child) {
  margin-right: 8px
}

table.md-table th.md-column.md-active,
table.md-table th.md-column.md-active md-icon {
  color: rgba(0, 0, 0, .87)
}

table.md-table th.md-column.md-active md-icon.md-sort-icon,
table.md-table th.md-column:hover md-icon.md-sort-icon {
  opacity: 1
}

table.md-table tr.md-row[data-ng-repeat].ng-leave,
table.md-table tr.md-row[ng-repeat].ng-leave,
table.md-table tr.md-row[ng\:repeat].ng-leave,
table.md-table tr.md-row[x-ng-repeat].ng-leave {
  display: none
}

table.md-table.md-row-select tbody.md-body>tr.md-row {
  transition: background-color .2s
}

table.md-table.md-row-select tbody.md-body>tr.md-row:not([disabled]):hover {
  background-color: #eee!important
}

table.md-table.md-row-select tbody.md-body>tr.md-row.md-selected {
  background-color: #f5f5f5
}

table.md-table.md-row-select td.md-cell:first-child,
table.md-table.md-row-select th.md-column:first-child {
  width: 20px;
  padding: 0 0 0 24px
}

table.md-table.md-row-select td.md-cell:nth-child(2),
table.md-table.md-row-select th.md-column:nth-child(2) {
  padding: 0 24px
}

table.md-table.md-row-select td.md-cell:nth-child(n+3):nth-last-child(n+2),
table.md-table.md-row-select th.md-column:nth-child(n+3):nth-last-child(n+2) {
  padding: 0 54px 0 0;
}

table.md-table:not(.md-row-select) td.md-cell:first-child,
table.md-table:not(.md-row-select) th.md-column:first-child {
  padding: 0 24px
}

table.md-table:not(.md-row-select) td.md-cell:nth-child(n+2):nth-last-child(n+2),
table.md-table:not(.md-row-select) th.md-column:nth-child(n+2):nth-last-child(n+2) {
  padding: 0 56px 0 0
}

table.md-table td.md-cell,
table.md-table th.md-column {
  vertical-align: middle;
  text-align: left
}

table.md-table td.md-cell>*,
table.md-table th.md-column>* {
  vertical-align: middle
}

table.md-table td.md-cell:last-child,
table.md-table th.md-column:last-child {
  padding: 0 24px 0 0
}

table.md-table td.md-cell.md-clickable,
table.md-table th.md-column.md-clickable {
  cursor: pointer
}

table.md-table td.md-cell.md-clickable:focus,
table.md-table th.md-column.md-clickable:focus {
  outline: 0
}

table.md-table td.md-cell.md-numeric,
table.md-table th.md-column.md-numeric {
  text-align: right
}

table.md-table td.md-cell md-checkbox,
table.md-table th.md-column md-checkbox {
  margin: 0;
  width: 20px
}

table.md-table td.md-cell {
  color: rgba(0, 0, 0, .87);
  font-size: 13px;
  border-top: 1px rgba(0, 0, 0, .12) solid
}

table.md-table td.md-cell.md-numeric md-select {
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end
}

table.md-table td.md-cell.md-numeric md-select .md-select-value {
  -webkit-flex-grow: initial;
  -ms-flex-positive: initial;
  flex-grow: initial
}

table.md-table td.md-cell.md-placeholder {
  color: rgba(0, 0, 0, .26)
}

table.md-table td.md-cell md-select>.md-select-value>span.md-select-icon {
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  color: rgba(0, 0, 0, .54);
  width: 18px;
  text-align: right
}

table.md-table td.md-cell md-select>.md-select-value>span.md-select-icon:after {
  -webkit-transform: scaleY(0.4) scaleX(0.8);
  transform: scaleY(0.4) scaleX(0.8)
}
