/*----------  Base Styles  ----------*/

.app-container {
  height: 100%;
}

* {
  box-sizing: border-box;
}

.pointer {
  cursor: pointer;
}

.faded {
  opacity: .5;
}


/*----------  Material Design Override  ----------*/

@media (min-width: 960px) {
  .md-padding {
    padding: 25px!important;
  }
}


/*----------  Buttons ----------*/

.md-button.primary {
  background: $primary;
  color: white;
  margin-top: 11px;
  min-width: 100px;
  &:hover,
  &:focus,
  .md-focused {
    background: $primaryContrast;
    background-color: $primaryContrast !important;
  }
}


/*----------  Background color  ----------*/

.background {
  &-white {
    background-color: white;
  }
}


/*----------  Tables  ----------*/

table {
  thead {
    background: $lightGray;
    th {
      color: $mediumGray!important;
    }
  }
  tbody {
    tr {
      height: 49px!important;
    }
  }
  md-checkbox {
    .md-icon {
      border-color: $lighterGray;
    }
  }
}


/*----------  Panels  ----------*/

.leftpanel {
  min-width: 420px!important;
  padding-top: 3px;
}

.rightpanel {
  z-index: 1;
  padding-top: 5px;
  min-width: 576px;
  max-width: 576px!important;
  box-shadow: 0px 1px 6.86px 0.14px rgba(0, 0, 0, 0.16);
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}

.middlepanel {
  min-width: 380px;
  z-index: 1;
  box-shadow: 0px 1px 6.86px 0.14px rgba(0, 0, 0, 0.16);
}

.gray-panel-bg {
  background-color: $rightPanelGray;
}

.white-panel-bg {
  background-color: white;
}


/*----------  Mini-overrides  ----------*/

.no-margin {
  &-bottom {
    margin-bottom: 0!important;
  }
  &-left {
    margin-left: 0!important;
  }
  &-right {
    margin-right: 0!important;
  }
  &-top {
    margin-top: 0!important;
  }
}

.float {
  &-left {
    float: left!important;
  }
  &-right {
    float: right!important;
  }
  &-none {
    float: none!important;
  }
}


/*----------  Scrollbars  ----------*/

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-button {
  width: 48px;
  height: 48px;
}

::-webkit-scrollbar-thumb {
  background: #b5b4b5;
  border: 0px none #686768;
  border-radius: 0px;
}

::-webkit-scrollbar-track {
  background: transparent;
  border: 0;
}

::-webkit-scrollbar-corner {
  background: transparent;
  display: none;
}
