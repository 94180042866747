/*----------  Domains Search (mdchips)  ----------*/

domains-search {
  position: relative;
  border-bottom: solid 1px $borderGray;
}

@import 'filters';
@import './bulkDomainsOverlay/core';
@import 'domains-search-input';
@import 'domains-search-filter-form';
